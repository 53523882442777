.form_label {
  display: block;
  font-size: 16px;
  font-weight: 500;
  text-align: start;
  padding-bottom: 5px;
}
.form_input {
  border: 1px solid #a3a3a3;
  color: #a3a3a3;
  border-radius: 5px;
  padding: 10px;
  width: 100%;
  /* padding-top: ; */
}

.signin_button_divider {
  overflow: hidden;
}

/* .signin_button_divider::before,
.signin_button_divider::after {
  background-color: #686868;
  content: "";
  display: inline-block;
  height: 1px;
  vertical-align: middle;
  width: 40%;
} */
