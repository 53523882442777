@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

*::-webkit-scrollbar {
  display: none;
}

.main_content {
  width: 100%;
  max-width: 80rem;
  margin: auto;
}

.padding_main_content {
  padding-left: 3.75rem;
  padding-right: 3.75rem;
}

.grad_text {
  background: -webkit-linear-gradient(left, #d956a7 10%, #565dfa);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
