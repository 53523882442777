.mobile_nav,
.mobile_toggle_icon {
  display: none;
}

@media screen and (max-width: 1080px) {
  .web_nav_options {
    display: none;
  }
  .mobile_nav,
  .mobile_toggle_icon {
    display: block;
  }

  .header_btn {
    flex-direction: row;
  }
}

@media screen and (max-width: 400) {
  .header_btn {
    flex-direction: column;
  }
}
